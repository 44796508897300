import React from "react";
import { SquareIcon } from "@hiyllo/ux/square-icon";
import { useSelf } from "@hiyllo/omni-continuity";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import {
  faAngleLeft,
  faBellSlash,
  faBug,
  faCalendar,
  faCogs,
  faComments,
  faContactBook,
  faEnvelope,
  faFolder,
  faQuestionCircle,
  faSearch,
  faSquareKanban,
  faUsers,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertsPill } from "./alerts-pill";
import { useConfig } from "../../../platform/config/config-context";
import { Features } from "../../../types/navigation/features";
import { useNavigate, useNavigateTo, usePath } from "@hiyllo/omni-router";
import { styled } from "@hiyllo/ux/styled";
import { useSearchOverlay } from "../../../main/search-provider";
import {
  useHoverTriggeredWrappedPopOver,
  useWrappedPopOver,
} from "@hiyllo/ux/context-menu";
import { SidebarTakeover } from "./dock/sidebar-takeover";
import { SelfImage } from "./dock/self-image";
import { type HiylloIcon, NymblIconLight } from "@hiyllo/icons/main";
import { Icon } from "@hiyllo/ux/icon";
import { useShowNymblHome } from "../../../platform/xp";
import { MiniFeedbackForm } from "../../feedback/mini-feedback-form";
import { BuiltInFolderEnum } from "../../../types/mail/organization/builtin-folders";
import { AlertFeature } from "../../../types/alerts/alert";
import { useAlertsContext } from "@hiyllo/omni-alerts/main";
import { motion } from "framer-motion";
import { useTheme } from "@hiyllo/ux/theme";
import { useMuted } from "../../../main/mute-provider";

const SidebarCloseButton = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  color: $theme.foreground,
  height: 40,
  width: 40,
  borderRadius: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LeftDockTopBar = styled<"div">("div", {
  display: "flex",
  flexDirection: "column",
  gap: 10,
  alignItems: "center",
  overflowY: "auto",
  marginBottom: 15,
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  // height: 0,
});

const LeftDockBottomBar = styled<"div">("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 15,
  cursor: "pointer",
});

const DockElement = styled("div", ({ $theme }) => ({
  width: 80,
  flexGrow: 0,
  flexShrink: 0,
  height: "calc(100% - 40px)",
  paddingTop: 20,
  paddingBottom: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  background: $theme.background1,
}));

const TooltipContainer = styled("div", {
  height: 42.5,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});
const Tooltip = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  boxShadow: $theme.surfaceShadow,
  padding: 7.5,
  borderRadius: 10,
  color: $theme.foreground,
  fontSize: 12,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
}));

const RedBadge = styled("div", ({ $theme }) => ({
  width: 15,
  height: 15,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: $theme.foreground,
  fontFamily: "hiyllo",
  fontSize: 10,
  background: $theme.buttonBackground,
  position: "absolute",
  bottom: 0,
  right: 0,
  zIndex: 2,
  fontWeight: "bold",
  cursor: "pointer",
  userSelect: "none",
  pointerEvents: "none",
}));

export const LeftDockAppButton = ({
  icon,
  onClick,
  isActive,
  tooltip,
  alertFeature,
}: {
  isActive: boolean;
  icon: IconDefinition | HiylloIcon;
  onClick: () => void;
  withGradient?: boolean;
  tooltip: string | null;
  alertFeature?: AlertFeature;
}): JSX.Element => {
  const { HoverTriggerContainer, CXMenuContainer } =
    useHoverTriggeredWrappedPopOver({
      offset: { x: 47.5, y: 0 },
      fadeAnimation: true,
      delay: 0,
    });

  const { breakdown } = useAlertsContext();
  const alertCount: number = alertFeature ? breakdown?.[alertFeature] ?? 0 : 0;

  return (
    <>
      <HoverTriggerContainer>
        <div style={{ position: "relative" }}>
          <SquareIcon isActive={isActive} size={42.5} onClick={onClick}>
            <motion.div
              animate={
                alertCount > 0
                  ? {
                    x: -5.5,
                    y: -5.5,
                  }
                  : {
                    x: 0,
                    y: 0,
                  }
              }
            >
              <Icon icon={icon} color="white" />
            </motion.div>
          </SquareIcon>
          {alertCount > 0 ? (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              style={{
                position: "absolute",
                bottom: 4,
                right: 4,
              }}
            >
              <RedBadge>{alertCount}</RedBadge>
            </motion.div>
          ) : null}
        </div>
      </HoverTriggerContainer>
      <CXMenuContainer>
        {tooltip !== null ? (
          <TooltipContainer>
            <Tooltip>{tooltip}</Tooltip>
          </TooltipContainer>
        ) : null}
      </CXMenuContainer>
    </>
  );
};

export const LeftDock = (): JSX.Element => {
  const path = usePath();
  const self = useSelf();
  const eNavigate = useNavigate();
  const config = useConfig();
  const searchOverlay = useSearchOverlay();
  const [showSidebar, setShowSidebar] = React.useState(false);

  const onShowSidebar = React.useCallback(() => {
    setShowSidebar(true);
  }, []);

  const onHideSidebar = React.useCallback(() => {
    setShowSidebar(false);
  }, []);

  const onClickNymblHome = useNavigateTo({
    feature: Features.home,
    params: null,
  });

  const onClickMail = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "mail",
      folder: BuiltInFolderEnum.inbox,
    },
  });

  const onClickChat = useNavigateTo({
    feature: Features.chat,
    params: null,
  });

  const onClickTasks = useNavigateTo({
    feature: Features.tasks,
    params: null,
  });

  const onClickCalendar = useNavigateTo({
    feature: Features.calendar,
    params: null,
  });

  const onClickSupport = useNavigateTo({
    feature: Features.support,
    params: null,
  });

  const isSolo = config.isSolo === true;
  const showNymblHome = useShowNymblHome();
  const {
    isOpen: feedbackIsOpen,
    open: openFeedback,
    close: closeFeedback,
    CXMenuContainer,
    ref,
  } = useWrappedPopOver<HTMLDivElement>({
    offset: {
      top: 40,
      left: 60,
    },
    displayMode: "fixed",
    disableMaxHeight: true,
  });

  const muted = useMuted();

  return (
    <LayoutGroup>
      <>
        <CXMenuContainer>
          <div style={{ position: "relative" }}>
            <div
              style={{
                zIndex: 10,
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
            >
              <MiniFeedbackForm onClose={closeFeedback} />
            </div>
          </div>
        </CXMenuContainer>
        <DockElement className="Dock">
          <LeftDockTopBar className="no-scroll-bar" id="dock-container">
            <LeftDockAppButton
              icon={faSearch}
              onClick={searchOverlay.toggle}
              isActive={false}
              tooltip="Search everything"
            />

            {showNymblHome ? (
              <LeftDockAppButton
                icon={NymblIconLight}
                onClick={onClickNymblHome}
                isActive={path.feature === Features.home}
                tooltip="Home"
              />
            ) : null}

            {!isSolo ? (
              <LeftDockAppButton
                icon={faComments}
                onClick={onClickChat}
                isActive={path.feature === Features.chat}
                tooltip="Chat"
                alertFeature={AlertFeature.chat}
              />
            ) : null}

            {config.tasks?.enabled !== false ? (
              <LeftDockAppButton
                icon={faSquareKanban}
                onClick={onClickTasks}
                isActive={path.feature === Features.tasks}
                tooltip="Tasks & Projects"
                alertFeature={AlertFeature.tasks}
              />
            ) : null}

            <LeftDockAppButton
              icon={faCalendar}
              onClick={onClickCalendar}
              isActive={path.feature === Features.calendar}
              tooltip="Calendar"
              alertFeature={AlertFeature.calendar}
            />
            {!isSolo ? (
              <LeftDockAppButton
                icon={faEnvelope}
                onClick={onClickMail}
                isActive={path.feature === Features.mail}
                tooltip="Mail"
                alertFeature={AlertFeature.mail}
              />
            ) : null}

            <LeftDockAppButton
              icon={faFolder}
              onClick={() => {
                eNavigate({
                  feature: Features.stuff,
                  params: {
                    view: "container",
                    uuid: null,
                  },
                });
              }}
              isActive={path.feature === Features.stuff}
              tooltip="Stuff"
            />

            {!isSolo ? (
              <LeftDockAppButton
                icon={faUsers}
                onClick={() => {
                  eNavigate({
                    feature: Features.organization,
                    params: { view: "dashboard" },
                  });
                }}
                isActive={path.feature === Features.organization}
                tooltip="Organization"
              />
            ) : null}

            {!isSolo ? (
              <LeftDockAppButton
                icon={faContactBook}
                onClick={() => {
                  eNavigate({
                    feature: Features.contacts,
                    params: { view: 'contacts-list' },
                  });
                }}
                isActive={path.feature === Features.contacts}
                tooltip="Contacts"
              />
            ) : null}

            {self.isAdmin ? (
              <LeftDockAppButton
                icon={faCogs}
                onClick={() => {
                  eNavigate({
                    feature: Features.workspaceConfig,
                    params: null,
                  });
                }}
                isActive={false}
                tooltip="Workspace Settings"
              />
            ) : null}
            {/* <LeftDockAppButton
              icon={faQuestionCircle}
              onClick={onClickSupport}
              isActive={path.feature === Features.support}
              tooltip="Help & Support"
            /> */}
            <div ref={ref}>
              <LeftDockAppButton
                icon={faBug}
                onClick={openFeedback}
                isActive={feedbackIsOpen}
                tooltip="Send Feedback"
              />
            </div>
          </LeftDockTopBar>
          <LeftDockBottomBar>
            <AlertsPill />

            <AnimatePresence>
              {muted ? <motion.div style={{ overflow: "hidden", marginTop: -15, }} initial={{ height: 0 }} animate={{ height: "" }} exit={{ height: 0 }}>
                <div style={{ paddingTop: 15 }}>
                  <FontAwesomeIcon icon={faBellSlash} color="white" />
                </div>
              </motion.div> : null}
            </AnimatePresence>

            {!showSidebar ? (
              <SelfImage onClick={onShowSidebar} />
            ) : (
              <SidebarCloseButton onClick={onHideSidebar}>
                <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
              </SidebarCloseButton>
            )}
          </LeftDockBottomBar>
        </DockElement>
        <SidebarTakeover
          showSidebar={showSidebar}
          onCloseSidebar={onHideSidebar}
        />
      </>
    </LayoutGroup>
  );
};
