import { ReactEditor } from "slate-react";
import { BooleanMarkEnum, toggleBooleanMark } from "../marks/boolean-marks";
import React from "react";

export function useOnKeyDown(editor: ReactEditor) {
  return React.useCallback((event: React.KeyboardEvent) => {
    if (event.metaKey) {
      if (event.key === "b") {
        event.preventDefault();
        toggleBooleanMark(editor, BooleanMarkEnum.bold);
      }
      else if (event.key === "i") {
        event.preventDefault();
        toggleBooleanMark(editor, BooleanMarkEnum.italic);
      }
      else if (event.key === "u") {
        event.preventDefault();
        toggleBooleanMark(editor, BooleanMarkEnum.underline);
      }
    }
  }, [editor]);
}