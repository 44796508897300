export type FormatType = Record<string, unknown | string | number>;

export interface EditorFormatType {
  value: number;
  label: string;
}

export const Formats: EditorFormatType[] = [
  {
    value: 7.5,
    label: "7.5pt",
  },
  {
    value: 10,
    label: "10pt",
  },
  {
    value: 12.5,
    label: "12.5pt",
  },
  {
    value: 15,
    label: "15pt",
  },
  {
    value: 20,
    label: "20pt",
  },
  {
    value: 25,
    label: "25pt",
  },
  {
    value: 30,
    label: "30pt",
  },
  {
    value: 40,
    label: "40pt",
  },
  {
    value: 50,
    label: "50pt",
  },
  {
    value: 75,
    label: "75pt",
  },
  {
    value: 100,
    label: "100pt",
  },
];