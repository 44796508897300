import React from "react";
import {
  type CalendarWeekAgendaParamsType,
  type CalendarEDataParamsType,
} from "@hiyllo/omni-common/src/types/navigation/edata";
import { LeftSidebar } from "../../components/left-sidebar";
import { LSHeader } from "../../components/ls-header";
import { MainContent } from "../../components/main-content";
import { CreateEventView } from "../../../calendar/views/create-event-view";
import {
  faCalendar,
  faCalendarLinesPen,
  faPlus,
  faTimesCircle,
} from "@fortawesome/pro-light-svg-icons";
import { useNavigate, useNavigateTo, usePath } from "@hiyllo/omni-router";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { LSButton } from "../../components/ls-button";
import { WeekView } from "../../../calendar/views/week-view";
import { EventDetailsView } from "../../../calendar/views/event-details/event-details-view";
import { ManageSchedules } from "../../../calendar/book-a-time/manage-schedules";
import { ManageSchedule } from "../../../calendar/book-a-time/manage-schedule";
import { CircleButton } from "@hiyllo/ux/circle-button";
import moment from "moment";
import { SearchOverlay } from "@hiyllo/omni-search";
import {
  type SearchResultType,
  type PersonSearchResultType,
} from "@hiyllo/omni-common/src/types/search/search-results";
import { useIsMobile } from "../../../../web-utils/mobile";
import { MiniMonthCalendar } from "@hiyllo/ux/month-calendar";
import { Input } from "@hiyllo/ux/input";
import { styled } from "@hiyllo/ux/styled";
import { UserImage } from "@hiyllo/omni-images/main";
import { GUEST_COLOR_BY_INDEX } from "../../../calendar/views/consts/guest-color-by-index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsSolo } from "../../../../platform/hooks/use-is-solo";
import { useLookupByUserId } from "@hiyllo/omni-continuity";
import { TabDetails } from "../../tabbing/tabs-provider";
import { IS_BETA_ENV } from "../../../../platform/xp";

const Container = styled("div", ({ $theme }) => ({
  marginLeft: 20,
  marginRight: 20,
  backgroundColor: $theme.background3,
  borderRadius: 10,
}));

const UserViewElem = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: 40,
  paddingLeft: 10,
  paddingRight: 10,
}));

const UserView = React.memo(function UserView(props: {
  userId: string;
  index: number;
  onRemove: (userId: string) => void;
}): JSX.Element {
  const lookup = useLookupByUserId(props.userId);

  if (!lookup.isReady) {
    return <div />;
  }

  return (
    <UserViewElem>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <div
          style={{
            height: 10,
            width: 10,
            backgroundColor:
              GUEST_COLOR_BY_INDEX[
              props.index as keyof typeof GUEST_COLOR_BY_INDEX
              ],
            borderRadius: "50%",
          }}
        />
        <UserImage userId={props.userId} width={20} />
        {lookup.data.name}
      </div>
      <FontAwesomeIcon
        icon={faTimesCircle}
        style={{ cursor: "pointer" }}
        onClick={() => props.onRemove(props.userId)}
      />
    </UserViewElem>
  );
});

const CalendarSearch = React.memo(function CalendarSearch(): JSX.Element {
  const [showSearch, setShowSearch] = React.useState(false);
  const navigate = useNavigate();
  const params = usePath().params as CalendarEDataParamsType;

  const onRemove = React.useCallback(
    (userId: string) => {
      const additionalUsers =
        (params as CalendarWeekAgendaParamsType | null)?.additionalUsers ?? [];

      navigate({
        feature: Features.calendar,
        params: {
          ...((params as CalendarWeekAgendaParamsType) ?? {}),
          view: "week-agenda",
          additionalUsers: additionalUsers?.filter((x) => x !== userId),
        },
      });
    },
    [navigate, params],
  );

  if (params != null && params?.view !== "week-agenda") {
    return <div />;
  }

  const additionalUsers = params?.additionalUsers ?? [];

  return (
    <>
      <Container>
        <Input
          placeholder={
            additionalUsers.length > 0
              ? "Add more people..."
              : "View someone's calendar..."
          }
          fullWidth
          onClick={() => setShowSearch(true)}
          inputStyle={{ cursor: "pointer" }}
        />
        {additionalUsers.map((userId, i) => (
          <UserView
            key={userId}
            userId={userId}
            onRemove={onRemove}
            index={i}
          />
        ))}
      </Container>
      {showSearch ? (
        <SearchOverlay
          onClose={() => setShowSearch(false)}
          onSelectResult={(result: SearchResultType) => {
            const sr = result as PersonSearchResultType;
            navigate({
              feature: Features.calendar,
              params: {
                ...((params as CalendarWeekAgendaParamsType) ?? {}),
                view: "week-agenda",
                additionalUsers: [...additionalUsers, sr.user.userId],
              },
            });
            setShowSearch(false);
          }}
          filterType={["user"]}
        />
      ) : null}
    </>
  );
});

export const CalendarFeature = (props: {
  params: CalendarEDataParamsType;
}): JSX.Element => {
  const next15MinuteIntervalDate = React.useMemo(() => {
    const now = moment();
    const minutes = now.minutes();
    const next15Minutes = Math.ceil(minutes / 15) * 15;
    return now.minutes(next15Minutes).seconds(0).milliseconds(0).toDate();
  }, []);

  const onCreateEvent = useNavigateTo({
    feature: Features.calendar,
    params: {
      view: "create-event",
      timing: {
        start: next15MinuteIntervalDate,
        end: moment(next15MinuteIntervalDate).add(1, "hours").toDate(),
      },
      guestUserIds:
        props.params?.view === "calendar-of" ? [props.params.userId] : [],
    },
  });
  const onHome = useNavigateTo({
    feature: Features.calendar,
    params: null,
  });
  const onClickBookATime = useNavigateTo({
    feature: Features.calendar,
    params: { view: "book-a-time" },
  });

  const params = usePath().params as CalendarEDataParamsType;
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const onChangeDate = React.useCallback(
    (date: Date) => {
      if (
        params == null ||
        params?.view === "week-agenda" ||
        params?.view === "calendar-of"
      ) {
        navigate({
          feature: Features.calendar,
          params:
            params == null
              ? {
                view: "week-agenda",
                weekOf: date,
              }
              : {
                ...params,
                weekOf: date,
              },
        });
      }
    },
    [navigate, params],
  );
  const isSolo = useIsSolo();

  return (
    <TabDetails icon={faCalendar} label="Calendar">
      {params == null || (!isMobile && params.view !== "create-event") ? (
        <LeftSidebar>
          <LSHeader
            label="Calendar"
            actionButton={
              <CircleButton onClick={onCreateEvent} icon={faPlus} size={30} />
            }
          />
          {!isSolo ? <CalendarSearch /> : null}
          <div
            style={{
              paddingTop: 20,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MiniMonthCalendar
              onChangeDate={onChangeDate}
              weekOf={
                props.params != null &&
                  "weekOf" in props.params &&
                  props.params.weekOf != null
                  ? props.params.weekOf
                  : new Date()
              }
              fullWidth
            />
          </div>
          <div style={{ height: 50 }} />
          {IS_BETA_ENV ? <LSButton
            isActive={props.params?.view === "book-a-time"}
            label="Book a Time Schedules"
            icon={{ fa: faCalendarLinesPen }}
            onClick={onClickBookATime}
          /> : null}
        </LeftSidebar>
      ) : null}
      <MainContent noPadding>
        {props.params == null || props.params.view === "week-agenda" ? (
          <WeekView
            calendarForUsers={
              (params as CalendarWeekAgendaParamsType | null)
                ?.additionalUsers ?? []
            }
          />
        ) : props.params.view === "create-event" ? (
          <CreateEventView timing={props.params.timing} />
        ) : props.params.view === "event" ? (
          <div style={{ padding: 20 }}>
            <EventDetailsView
              eventUUID={props.params.eventUUID}
              onClose={onHome}
            />
          </div>
        ) : props.params.view === "book-a-time" ? (
          props.params.scheduleUUID == null ? (
            <ManageSchedules />
          ) : (
            <ManageSchedule uuid={props.params.scheduleUUID} />
          )
        ) : null}
      </MainContent>
    </TabDetails>
  );
};

export default CalendarFeature;
