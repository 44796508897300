import { styled } from "@hiyllo/ux/styled";
import React, { useImperativeHandle } from "react";
import { faBold, faItalic, faList, faPalette, faUnderline } from "@fortawesome/pro-light-svg-icons";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { BaseOperation, BaseText, createEditor, Descendant, Selection, Editor as SlateEditor, Transforms } from "slate";
import { Editable, ReactEditor, RenderLeafProps, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { useOnPaste } from "./hooks/use-on-paste";
import { Select } from "@hiyllo/ux/select";
import { Formats } from "./formats";
import { withVoidNodeCursorFix } from "./behaviors/void-node-cursor-fix";
import { withDeleteFirstLineBehavior } from "./behaviors/delete-first-line";
import { withImageDragIn } from "./behaviors/image-drag-in";
import { BooleanMarkEnum, toggleBooleanMark } from "./marks/boolean-marks";
import { useOnKeyDown } from "./behaviors/hotkeys";
import { ColorPicker } from "@hiyllo/ux/color-picker";
import { TypedEventEmitterV3 } from "@moopsyjs/toolkit";
import { useEditorMarks } from "./marks/use-editor-marks";
import { withCustomEnterBehavior } from "./behaviors/custom-enter";
import { Element, withEmbeds } from "./elements";
import { toggleBlock } from "./marks/block-marks";
import { DescendantType, DocumentContentsV2 } from "./types";

export const EditorCtx = React.createContext<ReactEditor | null>(null);

interface HiylloText extends BaseText {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  fontSize?: string | number;
  color?: string;
}

const Leaf = React.memo(function Leaf(props: RenderLeafProps): JSX.Element {
  const { attributes, children, leaf } = props;
  const { text, ...rest } = (leaf as HiylloText);

  const styles = React.useMemo(() => {
    const styles: React.CSSProperties = {};

    if (rest.bold) {
      styles.fontWeight = "bold";
    }

    if (rest.italic) {
      styles.fontStyle = "italic";
    }

    if (rest.underline) {
      styles.textDecoration = "underline";
    }

    if (rest.fontSize) {
      styles.fontSize = rest.fontSize;
      styles.lineHeight = `${Number(rest.fontSize) + 2.5}px`;
    }

    if (rest.color) {
      styles.color = rest.color;
    }

    return styles;
  }, [rest]);

  return (
    <span {...attributes} {...rest} style={styles} className={Object.keys(rest).join(" ")}>
      {children}
    </span>
  );
});

const initialValue: Descendant[] = [
  {
    // @ts-expect-error ---
    type: "paragraph",
    children: [{ text: "I'm a new document..." }],
  },
];

const EditorContainer = styled<"div", { noPadding?: boolean }>(
  "div",
  ({ $theme, noPadding }) => ({
    background: $theme.background1,
    height: noPadding ? "100%" : "calc(100% - 32px)",
    width: noPadding ? "100%" : "calc(100% - 32px)",
    display: "flex",
    flexDirection: "column",
    padding: noPadding ? 0 : 16,
  }),
);

const EditorDocumentName = styled("div", ({
  fontSize: 24,
  fontWeight: "bold",
  fontFamily: "hiyllo",
}));

const EditorMain = styled("div", ({
  display: "flex",
  flexDirection: "row",
  gap: 5,
  height: 0,
  flexGrow: 1,
}));

const EditorSidebar = styled("div", ({
  width: 170,
  paddingTop: 5,
  paddingBottom: 10,
  display: "flex",
  flexDirection: "column",
  gap: 17.5,
  //
}));

const EditorContentArea = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  borderRadius: 16,
  height: "calc(100% - 32px)",
  width: "calc(100% - 32px)",
  padding: 16,
  display: "flex",
  flexDirection: "column",
  whiteSpace: "pre-wrap",
  overflowY: "auto"
}));

const EditorContentContainer = styled("div", ({
  width: 0,
  flexGrow: 1,
}));

const EditorSidebarSimpleButtonContainer = styled<"div", { active: boolean }>(
  "div",
  ({ $theme, active }) => ({
    background: active ? $theme.buttonBackground : $theme.midground,
    height: 35,
    width: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    fontSize: 16,
    cursor: "pointer",
  }),
);

const SavingIndicatorRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 16,
});

const EditorSidebarSimpleButton = React.memo(
  function EditorSidebarSimpleButton(props: {
    active?: boolean;
    icon: IconDefinition;
    onClick?: (evt: React.MouseEvent) => void;
  }): JSX.Element {
    const onClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onClick?.(e);
      },
      [props.onClick],
    );

    return (
      <EditorSidebarSimpleButtonContainer
        active={props.active ?? false}
        onMouseDown={onClick}
      >
        <FontAwesomeIcon icon={props.icon} color="white" />
      </EditorSidebarSimpleButtonContainer>
    );
  },
);

const EditorSidebarSimpleButtonsContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 8,
  alignItems: "center",
}));

export interface EditorRef {
  editor: ReactEditor | null;
}

export interface EditorPropsType {
  initialContents?: DocumentContentsV2 | null;
  initialHTML?: string | null;
  onValueChanged: (contents: DocumentContentsV2, delta: BaseOperation[]) => void;
  isSaving?: boolean;
  name?: string | null;
  noPadding?: boolean;
  contentPrefix?: React.ReactNode | JSX.Element;
  contentSuffix?: React.ReactNode | JSX.Element;
  readOnly?: boolean;
  extraElementBelowName?: React.ReactNode | JSX.Element;
  onExportBlob?: null | ((blob: Blob) => void);
  onImageUploaded?: (image: Blob) => Promise<{ src: string, fsId: string }>;
  onSelectionChanged?: (selection: Selection) => void;
}

export type EditorEmitter = TypedEventEmitterV3<{ change: null }>;

const EditorFR = React.forwardRef<EditorRef, EditorPropsType>(
  function Editor(props, forwardedRef): JSX.Element {
    const blurredSelectionRef = React.useRef<Selection | null>(null);
    const editorRef = React.useRef<ReactEditor | null>(null);
    const emitter = React.useMemo(() => new TypedEventEmitterV3<{ change: null }>(), []);
    const editor: ReactEditor = React.useMemo(() => withEmbeds(
      withImageDragIn(
        withDeleteFirstLineBehavior(
          withVoidNodeCursorFix(
            withCustomEnterBehavior(
              withHistory(
                withReact(
                  createEditor()
                )
              )
            )
          )
        ),
        async file => {
          if (props.onImageUploaded == null) return {
            fsId: "",
            src: URL.createObjectURL(file),
          };

          return props.onImageUploaded(file);
        }
      )
    ) as ReactEditor, []);
    editorRef.current = editor;

    const onKeyDown = useOnKeyDown(editor);

    const onChange = React.useCallback((newValue: Descendant[]) => {
      emitter.emit("change", null);
      props.onValueChanged?.({ v2: true, descendants: newValue as DescendantType[] }, editorRef.current?.operations ?? []);
    }, [emitter, props]);

    useImperativeHandle(
      forwardedRef,
      () => ({
        editor,
      }),
      [editor],
    );
    const marks = useEditorMarks(editor, emitter);

    // @ts-expect-error ---
    const isBold = marks?.bold === true;

    // @ts-expect-error ---
    const isItalic = marks?.italic === true;

    // @ts-expect-error ---
    const isUnderline = marks?.underline === true;

    // @ts-expect-error ---
    const fontSize: number = marks?.fontSize ?? 15;

    const bold = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBooleanMark(editorRef.current, BooleanMarkEnum.bold);
    }, []);

    const italic = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBooleanMark(editorRef.current, BooleanMarkEnum.italic);
    }, []);

    const underline = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBooleanMark(editorRef.current, BooleanMarkEnum.underline);
    }, []);

    const bulletList = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBlock(editorRef.current, "bulleted-list");
    }, []);

    const collapse = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBlock(editorRef.current, "collapse", [{ type: "paragraph", children: [{ text: "" }] }]);
    }, []);

    const onPaste = useOnPaste(editor, props.onImageUploaded);

    const onBlur = React.useCallback(() => {
      if (editorRef.current == null) return;
      blurredSelectionRef.current = editorRef.current.selection ?? null;
    }, []);

    const onSelectColor = React.useCallback((color: string) => {
      SlateEditor.addMark(editor, "color", color);
      ReactEditor.focus(editor);
    }, [editor]);

    const value = (props.initialContents?.descendants as Descendant[] | void);

    return (
      <EditorCtx.Provider value={editor}>
        <EditorContainer noPadding={props.noPadding}>
          <EditorMain>
            <EditorSidebar>
              {props.name != null ? (
                <EditorDocumentName>{props.name}</EditorDocumentName>
              ) : null}
              {props.extraElementBelowName}
              {props.readOnly !== true ? <>
                <EditorSidebarSimpleButtonsContainer>
                  <EditorSidebarSimpleButton
                    icon={faBold}
                    onClick={bold}
                    active={isBold}
                  />
                  <EditorSidebarSimpleButton
                    icon={faItalic}
                    onClick={italic}
                    active={isItalic}
                  />
                  <EditorSidebarSimpleButton
                    icon={faUnderline}
                    onClick={underline}
                    active={isUnderline}
                  />
                  <EditorSidebarSimpleButton
                    icon={faList}
                    onClick={bulletList}
                    active={false}
                  />
                  {/* <EditorSidebarSimpleButton
                    icon={faArrowsMinimize}
                    onClick={collapse}
                    active={false}
                  /> */}
                  <ColorPicker onSelectColor={onSelectColor}>
                    <EditorSidebarSimpleButton icon={faPalette} active={false} />
                  </ColorPicker>

                  <div style={{ width: 90 }}>
                    <Select
                      options={Formats}
                      value={fontSize}
                      onChangeValue={(value) => {
                        ReactEditor.focus(editor);
                        if (blurredSelectionRef.current != null) {
                          Transforms.select(editor, blurredSelectionRef.current);
                        }
                        SlateEditor.addMark(editor, "fontSize", value);
                      }}
                      fullWidth
                    />
                  </div>
                </EditorSidebarSimpleButtonsContainer>
              </> : null}

              <div style={{ flexGrow: 1 }} />

              {props.isSaving ? (
                <SavingIndicatorRow>
                  <LoadingSpinner />
                  Saving...
                </SavingIndicatorRow>
              ) : null}
            </EditorSidebar>
            <EditorContentContainer>
              <EditorContentArea>
                {props.contentPrefix}
                <Slate editor={editor} initialValue={value && value.length > 0 ? value : initialValue} onChange={onChange} onSelectionChange={props.onSelectionChanged}>
                  <Editable
                    renderElement={props => <Element {...props} />}
                    renderLeaf={props => <Leaf {...props} />}
                    // placeholder="Enter some text..."
                    disableDefaultStyles
                    onPaste={onPaste}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    style={{ border: 'none', outline: 'none', height: '100%', width: '100%' }}
                  />
                </Slate>
                {props.contentSuffix}
              </EditorContentArea>
            </EditorContentContainer>
          </EditorMain>
        </EditorContainer>
      </EditorCtx.Provider>
    );
  },
);

export const Editor = React.memo(EditorFR) as typeof EditorFR;